import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import bg from "../images/legal-bg.jpg"
import enkeli from "../images/enkeli.jpg"

const Säännöt = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry -Säännöt" />
    <div className="hero-area full-container">
      <div
        className="page-banner page-banner-image parallax"
        style={{ background: `url(${bg})` }}
      >
        <div className="container">
          <h1>Säännöt</h1>
        </div>
      </div>
    </div>

    <div id="content" className="content full pt-10 pb-10">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-8 col-md-offset-2">
            <h2>
              SUOMEN HAUTAUSTOIMINNAN KESKUSLIITTO RY, BEGRAVNINGSVERKSAMHETENS
              CENTRALFÖRBUND I FINLAND RF
            </h2>

            <h3>1 §. Nimi ja tarkoitus</h3>
            <img
              className="md:float-right5"
              style={{ width: "300px" }}
              src={enkeli}
              alt=""
            />
            <p>
              Yhdistyksen nimi on Suomen hautaustoiminnan keskusliitto,
              Begravningsverksamhetens centralförbund i Finland ja sen
              kotipaikka on Helsingin kaupunki.
            </p>

            <p>
              Tarkoituksena on toimia alan yhteisöjen keskusjärjestönä Suomessa
              ja
            </p>

            <ul>
              <li>kehittää hautaustoimintaa</li>
              <li>edistää alalla toimivien yhteistyötä</li>
              <li>edistää myönteistä suhtautumista</li> tuhkahautausta kohtaan
              <li>vaalia ja edistää hautauskulttuuria</li>
            </ul>

            <p>Tarkoituksensa toteuttamiseksi yhdistys</p>

            <ul>
              <li>harjoittaa koulutus-, kustannus- ja tiedotustoimintaa</li>
              <li>
                tekee aloitteita ja antaa lausuntoja viranomaisille
                hautaustoimintaa koskevista asioista
              </li>
              <li>
                auttaa neuvomalla ja ohjaamalla krematorioitten ylläpitäjiä
                laitosten hankinnassa, korjauksissa ja hoidossa
              </li>
              <li>
                ylläpitää yhteyksiä alan koti- ja ulkomaisiin järjestöihin sekä
                jäseniä kotimaassa ja ulkomailla
              </li>
            </ul>

            <p>
              Toimintansa tukemiseksi yhdistys voi järjestää rahankeräyksiä,
              juhlatilaisuuksia ja arpajaisia sekä vastaanottaa testamentteja,
              omistaa ja hallita toimintaansa varten tarpeellista irtainta
              omaisuutta ja kiinteistöjä.
            </p>

            <p>Näissä säännöissä käytetään yhdistyksestä nimitystä liitto.</p>

            <h3>2 §. Jäsenet</h3>

            <p>
              Liiton jäsenistön muodostavat varsinaiset jäsenet, yhteisöjäsenet
              ja kunniajäsenet. Liiton jäsenistä määrätään tarkemmin 8 ja 9
              §:ssä. Varsinaisen jäsenen ja yhteisöjäsenen hyväksymisestä liiton
              jäseneksi päättää liittohallitus. Kunniajäseneksi voi liitto
              liittohallituksen ehdotuksesta kutsua henkilön, joka erittäin
              kunnioitettavalla tavalla on toiminut liiton tarkoitusperien
              hyväksi. Myöskin ulkomaalainen voidaan kutsua liiton
              kunniajäseneksi.
            </p>

            <h3>3 §. Liittokokous</h3>

            <p>
              Liittokokous on liiton päättävä elin. Liiton kokouksissa ovat
              äänioikeutettuja liiton varsinaiset jäsenet, joiden äänioikeutta
              käyttävät ko. jäsenten valtuuttamat edustajat. Oikeutettuja
              osallistumaan liiton kokouksiin ovat myös liiton kunniajäsenet,
              yhteistyöjäsenten edustajat sekä liittohallituksen kutsumina
              liiton toimihenkilöt ja eri järjestöjen edustajat. Varsinainen
              liittokokous pidetään vuosittain viimeistään kesäkuun 30 päivänä,
              ellei liittokokous toisin päätä kuitenkin viimeistään 31.8.
              mennessä. Kokouspaikan määrää liittokokous, ellei se jätä asiaa
              liittohallituksen päätettäväksi. Jos liittohallitus niin päättää
              tai vähintään neljännes liiton jäsenistä sitä vaatii, voidaan
              varsinaisen liittokokouksen lisäksi kutsua koolle ylimääräinen
              liittokokous pidettäväksi liittohallituksen määräämällä
              paikkakunnalla.
            </p>

            <p>
              Valtuutettujen edustajien lukumäärä, jonka varsinainen jäsen on
              oikeutettu lähettämään liittokokoukseen, määräytyy joko ko.
              jäsenen henkilöjäsenmäärästä viimeksi päättyneen kalenterivuoden
              lopulla (liiton alueelliset jäsenyhdistykset) tai ko.
              jäsenyhteisön toimialueen asukasluvusta edellisen kalenterivuoden
              päättyessä (muut varsinaiset jäsenet) seuraavasti:
            </p>

            <p>Alueelliset jäsenyhdistykset</p>

            <p>
              1 – 500 henkilöjäsentä = 1 edustaja
              <br />
              501 – 1500 henkilöjäsentä = 2 edustajaa
              <br />
              1501 – henkilöjäsentä = 3 edustajaa
              <br />
            </p>

            <p>Muut varsinaiset jäsenet</p>

            <p>
              1 – 10.000 asukasta = 1 edustajaa
              <br />
              10.001 – 50.000 asukasta = 2 edustajaa
              <br />
              50.001 – 100.000 asukasta = 3 edustajaa
              <br />
              100.001 – asukasta = 4 edustajaa
              <br />
            </p>

            <p>
              Liittokokouksessa on jokaisella tämän pykälän nojalla
              valtuutetulla varsinaisella jäsenen edustajalla on yksi ääni.
              Valtakirjalla äänestäminen toisen edustajan puolesta ei ole
              sallittua.
            </p>

            <p>
              Kokouskutsu, jossa on ilmoitettava kokouksen paikka ja aika sekä
              lueteltava kokouksessa käsiteltäviksi tulevat asiat, on
              liittohallituksen toimesta vähintään kaksi viikkoa ennen kokousta
              kirjeitse lähetettävä jokaiselle liiton jäsenelle.
            </p>

            <p>
              Kutsu varsinaiseen liittokokoukseen on lähetettävä myös
              tilintarkastajille.
            </p>

            <p>Varsinaisessa liittokokouksessa käsitellään seuraavat asiat:</p>

            <ol>
              <li>Kokouksen puheenjohtajan ja varapuheenjohtajan vaali</li>
              <li>kokouksen sihteerin kutsuminen</li>
              <li>kahden pöytäkirjan tarkastajan vaali</li>
              <li>äänioikeutettujen nimenhuuto</li>
              <li>kokouksen laillisuuden ja päätösvaltaisuuden toteaminen</li>
              <li>
                toiminta- ja tilitarkastuskertomuksen esittäminen edellisen
                vuoden hallinnosta ja toiminnosta
              </li>
              <li>tilinpäätöksen vahvistaminen</li>
              <li>
                vastuuvapauden myöntäminen liittohallitukselle ja muille
                tilivelvollisille
              </li>
              <li>liittohallituksen jäsenmäärän vahvistaminen</li>
              <li>liittohallituksen erovuoroisten jäsenten vaali</li>
              <li>kahden tilintarkastajan ja heidän varamiestensä vaalit</li>
              <li>
                toiminta- ja taloussuunnitelman hyväksymin en meneillään
                olevalle tilikaudelle
              </li>
              <li>jäsenmaksun määrääminen seuraavalle tilikaudelle</li>
              <li>seuraavan varsinaisen liittokokouspaikan määrääminen</li>
            </ol>

            <p>
              Myös ylimääräisessä liittokokouksessa on käsiteltävä ylempänä
              kohdissa 1 – 5 esitellyt asiat.
            </p>

            <p>
              Kokouksessa voidaan käsitellä vain kokouskutsussa mainitut asiat.
              Asia, jonka jäsen tai liittohallituksen jäsen tai liiton keskeistä
              toimintaa hoitava toimihenkilö haluaa käsiteltäväksi varsinaisessa
              liittokokouksessa, on jätettävä liitto hallitukselle viimeistään
              maaliskuun 15 päivänä. Tässä järjestyksessä tehtyä ehdotusta
              esitettäessä liittokokoukselle on siihen liitettävä
              liittohallituksen lausunto.
            </p>

            <h3>4 § Liittohallitus</h3>

            <p>
              Liittoa edustaa ja sen asioita hoitaa liittohallitus, jonka
              muodostavat 5 – 7 jäsentä. Jäsenet valitaan kolmeksi vuodeksi
              kerrallaan ja vuosittain kolmannes on erovuorossa.
            </p>

            <p>
              Liittohallitus valitsee keskuudestaan puheenjohtajan ja
              varapuheenjohtajan, jotka kutsutaan myös liiton puheenjohtajaksi
              ja varapuheenjohtajaksi. Liittohallituksen sihteerinä ja
              esittelijänä toimii liiton pääsihteeri, joka voi olla
              liittohallituksen jäsen. Pääsihteeri on liiton toimeenpaneva
              toimenhaltija, jonka liittohallitus valitsee. Liittohallitus
              valitsee keskuudestaan tai ulkopuolelta taloudenhoitajan sekä muut
              toimihenkilöt.
            </p>
            <p>
              Jos liittohallituksen jäsen eroaa ennen toimikautensa päättymistä,
              valitaan seuraavassa varsinaisessa tai ylimääräisessä
              liittokokouksessa uusi jäsen hänen tilalleen jäljellä olevaksi
              toimikaudeksi.
            </p>

            <p>
              Liittohallitus kokoontuu puheenjohtajan kutsusta.
              Liittohallituksen kokouksissa on pidettävä pöytäkirjaa.
            </p>

            <p>Liittohallitus voi nimetä valiokuntia ja toimikuntia.</p>

            <p>
              Liittohallitus on päätösvaltainen, kun vähintään puheenjohtaja tai
              varapuheenjohtaja ja kolme sen jäsentä on kokouksessa läsnä.
              Äänten käydessä tasan puheenjohtajan ääni on ratkaiseva.
            </p>

            <h3>5 §. Nimenkirjoitus</h3>

            <p>
              Liiton nimen kirjoittavat puheenjohtaja ati varapuheenjohtaja
              jompikumpi yhdessä liittohallituksen sihteerin tai
              liittohallituksen määräämän henkilön kanssa.
            </p>

            <p>
              Liittohallitus voi oikeuttaa pääsihteerin yksin allekirjoittamaan
              liiton nimen.
            </p>

            <h3>6 §. Tilivuosi ja tilit</h3>

            <p>Liiton tilivuosi on kalenterivuosi.</p>

            <h3>7 §. Jäsenmaksut</h3>

            <p>
              Liiton yleisten menojen peittämiseksi on jokaisen varsinaisen
              jäsenen vuosittain suoritettava jäsenmaksu, joka voi olla eri
              jäsenryhmissä erilainen.
            </p>

            <p>
              Yhteistyöjäsenen jäsenmaksusta päättää niin ikään liittokokous.
            </p>

            <p>Liiton kunniajäsenet eivät suorita jäsenmaksua.</p>

            <h3>8 §. Liiton jäsenistö, sen toimivalta ja velvollisuudet</h3>

            <p>
              Liiton varsinaisia jäseniä ovat rekisteröidyt alueelliset jäsen
              yhdistykset sekä hautaus- ja krematoriotoimintaa harjoittavat
              oikeuskelpoiset yhteisöt, kunnat, seurakunnat tai vastaavat, jotka
              liittohallitus on hyväksynyt jäseniksi.
            </p>

            <p>Jokaisen alueellisen jäsenyhdistyksen on</p>

            <ul>
              <li>oltava rekisteröity yhdistys</li>
              <li>
                hyväksyttävä itselleen säännöt, jotka ollakseen pätevät, on
                liittohallituksen hyväksyttävä
              </li>
              <li>
                lähettävä vuosittain viimeistään tammikuun 15 päivänä
                liittohallitukselle ilmoitus jäsenmäärästä edellisen vuoden
                päättyessä
              </li>
              <li>
                pidettävä vuosittain viimeistään helmikuun kuluessa varsinainen
                vuosikokous
              </li>
              <li>
                lähetettävä vuosittain viimeistään maaliskuun 15 päivänä
                liittohallitukselle jäljennös vuosikertomuksesta päättyneeltä
                kalenterivuodelta samoi kuin jäljennös vuosikokouksen
                pöytäkirjasta
              </li>
            </ul>
            <p>
              Hautausmaa- ja krematoriotoimintaa harjoittavien jäsenten on
              lähetettävä viimeistään tammikuun 15 päivänä liittohallitukselle
              tilastot haudatuista ja krematoriotoiminnasta. Liiton
              yhteistyöjäseneksi voidaanhyväksyä oikeuskelpoinen yhteisö, joka
              on kiinnostunut liiton toiminnasta tai joka aatteellisessa,
              taloudellisessa tai järjestöllisessä suhteessa haluaa edistää
              liiton pyrkimyksiä.
            </p>
            <p>
              Mikäli liiton jäsen laiminlyö näiden tai omien sääntöjen mukaiset
              velvollisuutensa, liittohallitus voi päättää jäsenen erottamisesta
              liiton jäsenyydestä. erottamispäätös astuu voimaan heti.
            </p>

            <h3>9 §. Alueellisen jäsenyhdistyksen jäsenistö</h3>

            <p>
              Alueellisen jäsenyhdistyksen ryhmäjäseneksi voi liittyä kunta, se
              urakunta, uskonnollinen yhdyskunta, säätiö, rekisteröity yhdistys
              tai muu oikeuskelpoinen yhteisö, joka aatteellisessa,
              taloudellisessa tai järjestöllisessä suhteessa haluaa edistää
              jäsenyhdistyksen pyrkimyksiä. Tällä tavoin ryhmäjäseneksi
              liittyneeseen ryhmään kuuluvia henkilöitä ei kuitenkaan lueta
              siihen jäsenyhdistyksen määrään, joka ratkaisee montako edustajaa
              jäsenyhdistys saa valtuuttaa liiton kokouksiin.
            </p>
            <h3>10 §. Liiton sääntöjen muutokset</h3>

            <p>Näiden sääntöjen muutokset päättää liittokokous.</p>

            <p>
              Ehdotusta sääntöjen muuttamisesta ei voida käsitellä
              liittokokouksessa, ellei muutosehdotusta ole toimitettu
              liittohallitukselle viimeistään maaliskuun 15 päivänä. Sääntöjen
              muuttamiseksi vaaditaan, että liittohallitus puoltaa
              muutosehdotusta ja että varsinainen liittokokous vähintään 2/3:lla
              annetuista äänistä kannattaa ehdotusta.
            </p>
            <p>
              Sääntöjen muutosehdotuksesta on mainittava liittokokouksen
              kokouskutsussa.
            </p>

            <h3>11 §. Liiton purkautuminen</h3>

            <p>
              Liiton purkautumisesta päättää liittokokous vähintään 4/5:lla
              annetuista äänistä.
            </p>

            <p>
              Purkautumispäätös astuu voimaan, kun se vähintään 4/5:lla
              annetuista äänistä vahvistetaan toisessa liittokokouksessa, joka
              pidetään aikaisintaan 30 päivää ensimmäisen jälkeen.
            </p>

            <p>
              Liiton purkautuessa on sen jäljellä olevat varat luovutettava
              järjestölle, jonka tarkoitus vastaa liiton sääntöjen 1 §:ssä
              esitettyjä tavoitteita.
            </p>

            <h3>
              12 §. Muissa kohdin seurataan yhdistyksistä voimassa olevaa
              lainsäädäntöä.
            </h3>

            <h3>13 §. Saavutetut jäsenoikeudet säilyvät.</h3>

            <p>
              Nämä säännöt on liittokokous hyväksynyt 26.5.2000 ja hyväksytty
              yhdistysrekisteriin 6.6.2001
            </p>
            <p />
          </div>
        </div>

        <div className="spacer-45" />
      </div>
    </div>
  </Layout>
)

export default Säännöt
